/* Color Palette */
$primary-color: #dfa976; /* Highlight Color */
$secondary-color: #cfcfcf; /* Secondary Text Color */
$background-color: #121212; /* Background Color */
$card-bg-color: #1d1b1b; /* Card Background */
$text-color: #bbb; /* General Text Color */
$heading-color: #fff; /* Heading Color */
$border-color: #dedede; /* Border Color */
$button-hover-color: #cfcfcf; /* Button Hover */
$link-color: #f6c99f;
$link-hover-color: #cfcfcf;
$bold-color: #eeeeee;

/* Global Styles */
.title {
    text-align: center;
    color: #cfcfcf;
    font-size: 3.5rem;
    @media (max-width: 576px) {
      font-size: 2.5rem; 
    }
}

.title-bar {
    margin-top: 30px;
    .title {
        text-align: center;
        color: #cfcfcf;
        font-size: 2rem;
        @media (max-width: 576px) {
          font-size: 1.5rem;
        }
    }
    .name {
        text-align: center;
        color: #cfcfcf;
        font-size: 3.5rem;
        @media (max-width: 576px) {
          font-size: 2.5rem; 
        }
    }
}

.navbar-links {
    max-width: 900px;
    margin: auto;
    margin-top: 60px;
}

/* Portfolio Card */
.portfolio-card {
    max-width: 1080px;
    margin: auto;
    margin-top: 50px;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: $card-bg-color;

    .role, .company, .date {
        font-weight: 900;
        font-size: 1.1rem;
        color: $heading-color;
    }

    .company, .date {
        font-style: italic;
        color: $text-color;
        font-size: 0.9rem;
    }

    .description {
      margin-bottom:8px;  
      font-size: 0.95rem;
        color: $text-color;
        @media (max-width: 576px) {
            font-size: 0.85rem; 
        }
    }

    button {
        width: 100%;
        margin-top: 1rem;
        font-family: 'Montserrat', sans-serif;
        font-weight:500;
        background-color: $link-color;
        padding-left: 1.4rem;
        padding-right: 1.4rem;
        color: $background-color;
        margin-bottom: 8px;

        &:hover {
            background-color: $button-hover-color;
            color: $card-bg-color;
        }

        @media (min-width: 576px) {
            width: auto;
            margin-top: 0;
        }
    }

    .thumbnail-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }

    .thumbnail {
        width: 100%;
        height: 400px; 
        background-size: cover;
        background-position: center;
        border-radius: 8px;
    }

  /* STATUS BADGES */
.status-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
}

.status-badge {
  background-color: $text-color; /* Light blue background */
  color: $background-color; /* Dark blue text */
  padding: 8px 12px;
  border-radius: 6px;
  font-size: 0.9em;
  white-space: nowrap;
  font-weight: 500;
}
    
}

/* Image in Portfolio Card */
.portfolio-card-image {
    //max-width: 512px;
    height: auto;
    min-height: 500px;
    border-radius: 8px;
    margin: 1rem 0;
    @media (max-width: 576px) {
        margin-top: 0;
        max-width: 100%; 
        min-height: none;
    }
}
.project-details {
    max-width: 1080px;
    margin: auto;
    padding: 30px;
    line-height: 1.6;
  
    h2.title {
      font-size: 2.5rem;
      color: $primary-color;
      margin-bottom:12px;
    }
  
    .company-dates-status {
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: right;
    }
    
    .status-container {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      margin-top: 8px;
      margin-bottom: 12px;
    }
    
    .status-badge {
      background-color: $text-color; 
      color: $background-color;
      padding: 8px 12px;
      border-radius: 6px;
      font-size: 0.9em;
      white-space: nowrap;
      font-weight: 500;
    }
    

    .role, .company, .date {
      font-weight: bold;
      font-size: 1.1rem;
      margin-bottom: 1rem;
    }
  
    .description {
      margin-top: 10px;
      font-size: 1rem;
      margin-bottom: 8px;
    }
  
    @media (max-width: 576px) {
      padding: 15px;
      h2.title {
        font-size: 2rem;
      }
      .description {
        font-size: 0.9rem;
      }
    }
    button {
        width: 100%;
        margin-top: 1rem;
        font-family: 'Montserrat', sans-serif;
        font-weight:500;
        background-color: $link-color;
        color: $background-color;

        &:hover {
            background-color: $button-hover-color;
            color: $card-bg-color;
        }

        @media (min-width: 576px) {
            width: auto;
            margin-top: 0;
        }
    }
  }
  
  /* Video section styling */
  .video-section {
    margin-bottom: 20px;
    .video-container {
      margin-bottom: 20px;
      iframe, video {
        width: 100%;
        height: auto;
        min-height: 500px;
        border-radius: 8px;
      }
    }
  }
  
  .tools-and-skills {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    .tools, .skills {
      font-size: 1rem;
      color: $text-color;
    }
    @media (max-width: 576px) {
      flex-direction: column;
      .tools, .skills {
        font-size: 0.9rem;
        margin-bottom: 10px;
      }
    }
  }
  
  /* Carousel Styling */
  .carousel-image {
    max-width: 100%;
    height: auto;
    min-height: 500px;
    border-radius: 8px;
    @media (max-width: 576px) {

        min-height: 0;
    }
  }
  
  

/* Project Navigation */
.project-nav {
    max-width: 900px;
    margin: auto;
    text-align: center;
    color: $heading-color;

    @media (max-width: 576px) {
        max-width: 100%;
    }
}

/* Body and General Styles */
body {
    background-color: $background-color;
    color: $secondary-color;
    font-family: 'Roboto', sans-serif;
    strong{
      color: $bold-color;
    }
}

a {
    color: $link-color;
    text-decoration: none;

    &:hover {
        color: $link-hover-color;
        text-decoration: underline;
    }
}

h1, h2, h3 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
  }

ul {
    padding-left: 1.5rem; 
    list-style: disc; 
    color: $text-color;

    li {
        margin-bottom: 0.5rem; 
        font-size: 1rem;

        @media (max-width: 576px) {
            font-size: 0.9rem; 
        }
    }
}

/* Responsive Utilities */
@media (max-width: 576px) {
    /* Reduce margin for small screens */
    .portfolio-card, .footer-container, .project-details, .project-nav {
        margin-top: 60px;
        padding: 1rem;
        background-color: $card-bg-color;
        color: $text-color;
    }

    .title {
        font-size: 2.5rem;
        color: $heading-color;
    }

    button {
        width: 100%;
        background-color: $primary-color;
        color: $heading-color;

        &:hover {
            background-color: $button-hover-color;
        }
    }
}

/* Experience Card Styles */
.experience-card, .education-card {
    margin-bottom: 1.5rem;
    padding: 1.5rem;
    background-color: $card-bg-color;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    h4 {
        font-size: 1.5rem;
        margin-bottom: 0.5rem;
        color: $primary-color; 
    }

    p {
        margin-bottom: 0.5rem;
        color: $text-color;  
        font-size: 1rem;
    }

    ul {
        padding-left: 2rem; 
        
        li {
            font-size: 1rem;
            color: $secondary-color; 
            line-height: 1.5;
        }
    }

    .company{
      font-size: 1.5rem;
        margin-bottom: 0.5rem;
        color: $text-color; 
    }

    @media (max-width: 576px) {
        padding: 1rem;

        h4 {
            font-size: 1.25rem;
        }

        p, li {
            font-size: 0.9rem;
        }
    }

    .education-date, .education-university{
      font-size: 0.9rem;
    }
    .education-title{
      font-size:1.15rem;
      color: $primary-color; 
      .strong{
        font-size: 1.5rem;
        margin-bottom: 0.5rem;
        color: $text-color; 
      }
    }

    .experience-date, .experience-role-company{
      font-size: 0.9rem;
    }
    .experience-title{
      font-size:1.15rem;
      .strong{
        font-size: 1.5rem;
        margin-bottom: 0.5rem;
        color: $primary-color; 
      }
    }
}

/* About Container */
.about-container {
    max-width: 1080px;
    margin: auto;
    padding: 20px;
    background-color: $background-color; 
    color: $secondary-color; 

    h2 {
        font-size: 2.5rem;
        margin-bottom: 1.5rem;
        color: $heading-color;
    }
    
    h3 {
        font-size: 1.75rem;
        margin-top: 2rem;
        color: $heading-color;
    }
    
    p {
        font-size: 1rem;
        line-height: 1.5;
        margin-bottom: 0.5rem;
        color: $text-color;
    }
    
    div {
        margin-bottom: 1.5rem;
    }
}

/* Footer Styles */
.footer-container {
    max-width: 1440px;
    padding: 30px;
    display: flex;
    justify-content: center;
    margin: auto;
    margin-top: 120px;
    background-color: $background-color;
    border-radius: 8px;
    color: $secondary-color;

    @media (max-width: 576px) {
        margin-top: 60px;
        padding: 20px;
    }

    hr {
        border-color: $border-color;
    }

    .footer-col {
        margin-bottom: 20px;
        text-align: left;
        min-width:200px;

        h4 {
            font-size: 1.25rem;
            color: $heading-color;
            margin-bottom: 1rem;
        }

        p {
            margin-bottom: 0.5rem;
            color: $text-color;
            font-size: 0.9rem;
        }

        ul {
            padding-left: 0;
            list-style: none;

            li {
                margin-bottom: 0.5rem;

                a {
                    text-decoration: none;
                    color: $link-color;

                    &:hover {
                        color: $link-hover-color;
                    }
                }
            }
        }
    }

    @media (max-width: 768px) {
        .footer-col {
            text-align: center;

            ul {
                display: flex;
                justify-content: center;
                flex-wrap: wrap;

                li {
                    margin: 0 10px;
                }
            }
        }
    }
}

/* Navbar Links */
.navbar-links {
    max-width: 900px;
    margin: auto;
    margin-top: 60px;
  
    .nav-bar {
      display: flex;
      justify-content: space-around;
    }
  
    .nav-item {
      list-style: none;
    }
  
    .nav-link {
      padding: 10px 15px;
      text-transform: uppercase;
      text-decoration: none;
      font-size: 1.2rem;
      color: $secondary-color; 
      transition: color 0.3s ease, border-bottom 0.3s ease;
  
      &:hover {
        color: $primary-color; 
        border-bottom: 2px solid $primary-color; 
      }
  
      &.active {
        color: $primary-color; 
        border-bottom: 2px solid $primary-color; 
      }
    }
  }
  
  
  /* ProjectNav Styles */
  .project-nav {
    max-width: 1080px;
    margin: auto;
    margin-top: 30px;
    text-align: center;
  
    .project-nav-bar {
      display: flex;
      justify-content: center;
      padding: 1rem 0;
  
      .project-nav-item {
        margin: 0 1rem;
  
        .project-nav-link {
          color: $secondary-color;
          padding: 0.5rem 1.5rem;
          font-size: 1rem;
          transition: background-color 0.3s ease, color 0.3s ease;
  
          &:hover {
            background-color: $primary-color;
            color: $background-color;
          }
  
          &.active {
            background-color: $primary-color;
            color: $background-color;
          }
        }
      }
    }
  }
  
   /* Legal Container Styles */
.legal-container{
    max-width: 900px;
    margin:auto;
    margin-top:5vh;
}

/* SCROLL TO TOP BUTTON */
.scroll-to-top-btn {
  position: fixed;
  bottom: 40px;
  right: 40px;
  background-color: $card-bg-color;
  color: $text-color;
  border: 2px solid $text-color;
  border-radius: 50%;
  padding: 15px;
  font-size: 24px;
  cursor: pointer;
  z-index: 1000;
  transition: background-color 0.3s ease, transform 0.3s ease;
  display: inline-flex; /* Ensures button doesn’t stretch across width */
  align-items: center;
  justify-content: center;
  width: 50px; /* Set fixed width */
  height: 50px; /* Set fixed height */

  &:hover {
    background-color: $link-hover-color;
    transform: scale(1.1);
    color: $background-color;
    border-color: $background-color;
  }

  &:focus {
    outline: none;
  }
}

/* Smaller size on phone screens */
@media (max-width: 600px) {
.scroll-to-top-btn {
    bottom: 20px;
    right: 20px;
    padding: 10px;
    font-size: 20px;
    width: 40px; /* Reduced width for mobile */
    height: 40px; /* Reduced height for mobile */
    border: 2px solid $text-color;
}
}


  
//LANGUAGE

.language-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.language-item {
  margin-bottom: 8px; 
}

.language-name {
  font-weight: bold;
}

.language-proficiency {
  margin-left: 5px;
  font-style: italic;
}

  //SKILLS

  .skills-section {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0 !important;
  }
  
  .skills-column {
    flex: 1;
    margin-bottom: 0 !important;
  }
  
  .skills-container {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 8px;
    margin-bottom: 0 !important;
  }
  
  .skill-badge {
    background-color: $card-bg-color;
    color: $text-color;
    padding: 8px 12px;
    border-radius: 6px;
    font-size: 0.9em;
    white-space: nowrap;
  }

.languages-skills-container{
  margin-bottom: 0 !important;
  margin-top: 40px;
}
  

/* Carousel controls styling */
.carousel-control-prev,
.carousel-control-next {
  height: 58%; 
  margin-top:auto; margin-bottom: auto;

  @media (max-width: 600px) {
    height: 25%; 
    margin-top:auto; margin-bottom: auto;
  }
}

.carousel-control-prev {
  left: 10px; 
}

.carousel-control-next {
  right: 10px; 
}

.volunteer-description, .publication-abstract, .achievement-description {
  margin-bottom: 0 !important;
  
}


/* Contact Details styling */
.contact-details-container {
  text-align: center;
  margin: 20px 0;
}

.contact-item {
  margin: 5px 10px;
  font-size: 16px;
}

